.containerlogin{
    display: flex;
    flex-direction: row; 
    justify-content: end;
   
}
.containerlogin .row{
    justify-content: end;
}
.containerlogin .row>:nth-child(2){
    justify-content: end;
}
.containerlogin .row>:first-child{
    margin-right: 0rem;
    /* background-color: aquamarine; */
    border-radius: 50%;
}
.containerlogin .row>:nth-child(2){
    width: 40%;
}
.containerlogin .left-image{
    padding-top: 2rem;
    width: 80%;
    height: 100%;
    
}

.containerlogin .left-image >img{
    width: 100%;
    height: 100%;
}
.containerlogin > .form{
    padding-top: 4rem;
    width: 60%;
    justify-content: end;
} 

@media screen and (max-width:856px) {

    .containerlogin{
        display: flex;
        flex-direction: column; 
       
    }
    
}
