.card{
    margin:auto;
    border:solid 1px #dbdad7; 
    width:60%; 
    padding-left:10px!important;
    padding-bottom:10px!important;
    padding-right:10px!important;
    padding-top: 0px!important;
    
  }
  
  .card-title{
    margin:auto;
    padding:15px;
    background-color: var(--dark);
    color: white;
    width:80%;
  }
  
  div.card-body{
    padding:0px;
  }
  .custom-select{
    width:100%;
  }
  
  .btn2{
    margin-left:10%;
  }
  input {
    outline: 0!important;
    border-width: 0 0 2px!important;
    border-color: #d1d1cf!important;
  }
  input:focus {
    border-color: #d1d1cf!important;
   -webkit-box-shadow: none!important;
    box-shadow: none!important;
  }
  
  select{
  
   outline: 0!important;
    border-width: 0 0 2px!important;
    border-color: #d1d1cf!important;
  }
  
  select:focus{
      border-color: #d1d1cf!important;
   -webkit-box-shadow: none!important;
    box-shadow: none!important;
  }
  
  .radiobtn{
    margin-left: 3.5%;
  }
  
  .icons{
    margin:auto!important;
  
  }
  
  .fa{
    border-radius: 25px;
    width: 10%;
    margin-left: 5%;
    border:solid 2px #dbdad7;
    margin-top:5%;
    text-align: center;
  }
  
  .fa-plane{
    color:#1cad9f;
  }
  
  .fa-taxi{
    color: #c2f700;
  }
  
  .fa-train{
    color:red;
  }
  
  @media only screen and (max-width: 600px) {
    .card{
      margin:auto;
      border:solid 1px #dbdad7;
      width:90%;
      padding-left:10px!important;
      padding-bottom:10px!important;
      padding-right:10px!important;
      padding-top: 0px!important;
    
    }
  
    .fa{
      border-radius: 25px;
      width: 15%;
      margin-left: 5%;
      border:solid 2px #dbdad7;
      margin-top:5%;
      text-align: center;
    }
  }